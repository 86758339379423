<template>
    <div class="datafeed-content">
        <div class="datafeed-content__center">
            <div class="datafeed-content__center__inner">
                <AuthUser class="p-5 bg-white border rounded shadow"/>
                <hr>
                <AuthUserForm class="p-5 bg-white border rounded shadow"/>
                <hr>
                <UpdatePassword class="p-5 bg-white border rounded shadow"/>
                <hr>
                <FileUpload label="Upload Avatar" :fileTypes="['image/*']" endpoint="/users/auth/avatar"
                            @fileUploaded="updateUser" class="p-5 bg-white border rounded shadow"/>
            </div>
        </div>
    </div>
</template>

<script>
    import AuthUser from "@/components/AuthUser";
    import FileUpload from "@/components/FileUpload";
    import AuthUserForm from "@/components/AuthUserForm";
    import UpdatePassword from "@/components/UpdatePassword";

    export default {
        name: "UserView",
        components: {
            AuthUser,
            FileUpload,
            AuthUserForm,
            UpdatePassword,
        },
        methods: {
            updateUser() {
                this.$store.dispatch("auth/getAuthUser");
            },
        },
    };
</script>

<style lang="scss">
    hr {
        width: 60%;
    }
</style>
